let supportFormats = null;

const makeFormatSupportValue = (avif, webp) => ({ avif, webp });

function prioritizeImageFormats(obj, supportFormats = makeFormatSupportValue(false, false)) {
    if (Array.isArray(obj)) {
        return obj.map((item) => prioritizeImageFormats(item, supportFormats));
    } else if (obj && typeof obj === "object") {
        const keys = Object.keys(obj);
        const priorityExt = ["avif", "webp"];

        for (let key of keys) {
            const camelCaseKeys = [key + "Avif", key + "Webp"];
            const snakeCaseKeys = [key + "_avif", key + "_webp"];
            const isCamelCase = camelCaseKeys.every((k) => keys.includes(k));
            const isSnakeCase = snakeCaseKeys.every((k) => keys.includes(k));

            let formatKeys, currentExt;
            if (isCamelCase) {
                formatKeys = camelCaseKeys;
            } else if (isSnakeCase) {
                formatKeys = snakeCaseKeys;
            } else {
                continue;
            }

            currentExt = typeof obj[key] === "string" ? obj[key].split(".").pop()?.toLowerCase() : "";

            for (let i = 0; i < priorityExt.length; i++) {
                const ext = priorityExt[i];

                /**
                 * According to API doc, if original image is avif/webp, then _avif _webp fields WILL BE falsy value.
                 * When this case occurs, we need to take original image as output value.
                 */
                if (ext === currentExt && obj[key]) {
                    obj[formatKeys[i]] = obj[key];
                }

                let isSupport = supportFormats[ext];
                // Skip AVIF for GIF images, since API server does not convert to animated-avif yet
                if (isSupport && currentExt === 'gif' && ext === 'avif') {
                    isSupport = false;
                }

                // Forward Compatibility: still take obj[key] as final output
                if (isSupport && obj[formatKeys[i]]) {
                    obj[key] = obj[formatKeys[i]];
                    break;
                }
            }

            // To avoid API value is null
            if (!obj[key]) {
                obj[key] = location.origin + '/static/images/logo.svg';
            }
        }

        for (let key of Object.keys(obj)) {
            obj[key] = prioritizeImageFormats(obj[key], supportFormats);
        }
        return obj;
    }
    return obj;
}

export async function processImageFormats(json) {
    if (!supportFormats) {
        const [avif, webp] = await Promise.all([
            isAVIFSupported(),
            isWEBPSupported(),
        ]);
        supportFormats = makeFormatSupportValue(avif, webp);
    }
	return	prioritizeImageFormats(json, supportFormats);
}

// 是否支援 avif 格式
export function isAVIFSupported() {
    return new Promise((resolve) => {
        const img = new Image();
        img.src =
            "data:image/avif;base64,AAAAHGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZgAAAPBtZXRhAAAAAAAAAChoZGxyAAAAAAAAAABwaWN0AAAAAAAAAAAAAAAAbGliYXZpZgAAAAAOcGl0bQAAAAAAAQAAAB5pbG9jAAAAAEQAAAEAAQAAAAEAAAEUAAAAFQAAAChpaW5mAAAAAAABAAAAGmluZmUCAAAAAAEAAGF2MDFDb2xvcgAAAABoaXBycAAAAElpcGNvAAAAFGlzcGUAAAAAAAAAAQAAAAEAAAAOcGl4aQAAAAABCAAAAAxhdjFDgQAcAAAAABNjb2xybmNseAABAAEAAQAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAAB1tZGF0EgAKBxgADlgICAkyCB/xgAAghQm0";
        img.onload = () => {
            resolve(true); // 支持 AVIF
        };
        img.onerror = () => {
            resolve(false); // 不支持 AVIF
        };
    });
}

// 是否支援 webp 格式
export function isWEBPSupported() {
    return new Promise((resolve) => {
        const img = new Image();
        img.src =
            "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
        img.onload = () => {
            resolve(true); // 支持 WEBP
        };
        img.onerror = () => {
            resolve(false); // 不支持 WEBP
        };
    });
}

export function prependCMSURL4CMSImageValue(CMSURL, imgsrc) {
    let imgsrcLower = typeof imgsrc==='string' ? imgsrc.toLowerCase() : imgsrc;
    return imgsrcLower.indexOf('https://') === 0 || imgsrcLower.indexOf('http://') === 0 ? imgsrc : (CMSURL + imgsrc);
}
